document.addEventListener('turbolinks:load', function() {

  function updatePositions() {
    jQuery('.hiddenPosition').each(function(index) {
      jquery(this).val(index + 1)
    })
  }

  updatePositions()

  $('#response_options').on('cocoon:after-insert cocoon:after-remove', function() {
    updatePositions()
  });

  let ro = document.getElementById("response_options")

  if (ro) {
    var sortable = new Sortable(ro, {
      animation: 200,
      filter: ".addAssociation",
      onEnd: function() { updatePositions() },
    })
  }

  $('input[type=radio][name="question[availability_type]"]').bind('change', function(){
    if (this.value == 'weekly_time_windows') {
      $('#date-time-fields').hide(400)
      $('#time-fields').show(400)
    }
    else if (this.value == 'specific_dates_and_times') {
      $('#time-fields').hide(400)
      $('#date-time-fields').show(400)
    }
    else {
      $('#time-fields').hide(400)
      $('#date-time-fields').hide(400)
    }
  });

  $('.questionAdvancedOptionsLink').bind('click', function(e){
    e.preventDefault();
    $(this).siblings('.questionAdvancedOptions').toggle(400)
  });

  $('input[name$="[message_immediately]"]').bind('change', function(){
    // Show when radio is true, hide when radio is false
    var showOrHide = $(this).val()
    if (showOrHide === 'true') {
      $(this).parent().parent().parent().next().show(400)
    } else {
      $(this).parent().parent().parent().next().hide(400)
    }
  });
});

