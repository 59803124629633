import 'core-js/stable'
import 'regenerator-runtime/runtime'
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery';
import Sortable from 'sortablejs';
import 'selectize';

import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks';
Turbolinks.start();
Rails.start();
console.log('hellllll*****************************')

import 'bootstrap/dist/js/bootstrap';

import Chartkick from 'chartkick';
window.Chartkick = Chartkick;
window.Sortable = Sortable;

import moment from 'moment';
window.moment = moment;

import 'pc-bootstrap4-datetimepicker';

import '../src/javascript/cocoon';
import '../src/javascript/campaigns';
import '../src/javascript/datetime';
import '../src/javascript/groups';
import '../src/javascript/customer_promotions';
import '../src/javascript/organizations';
import '../src/javascript/rewards';
import '../src/javascript/signup_messages';
import '../src/javascript/questions';
import '../src/javascript/gifted_customer_promotions';
