import moment from 'moment';
document.addEventListener('turbolinks:load', function() {
  const dateTimePickerClass = '.datetimepicker'
  const datePickerClass = '.datetimepicker-date'

  // Using font-awesome 5 icons
  $.extend(true, $.fn.datetimepicker.defaults, {
    icons: {
      time: 'far fa-clock h4',
      date: 'far fa-calendar h4',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'fas fa-calendar-check',
      clear: 'far fa-trash-alt',
      close: 'far fa-times-circle'
    }
  });


  let addDatetimePicker = (e) => {
    let format = 'D MMM YYYY h[:]mm A';
    $(e).attr({'autocomplete': 'off'});
    $(e).datetimepicker({
      format: format,
      date: moment($(e).val(), format),
      sideBySide: true,
    });
  }

  $(dateTimePickerClass).each(function() {
    addDatetimePicker(this)
  });

  let addDatePicker = (e) => {
    let format = 'D MMM YYYY';
    $(e).attr({'autocomplete': 'off'});
    $(e).datetimepicker({
      format: format,
      date: moment($(e).val(), format),
    });
  }

  $(datePickerClass).each(function() {
    addDatePicker(this)
  });

  // Add datetime pickers to newly inserted elements
  $(document).on('cocoon:after-insert', function(e, insertedItem) {
    insertedItem.find(dateTimePickerClass).each(function() {
      addDatetimePicker(this)
    })
    insertedItem.find(datePickerClass).each(function() {
      addDatePicker(this)
    })
  });
});
