document.addEventListener('turbolinks:load', function() {
  $('.selectize').selectize({
    maxOptions: 8,
  });

  // Have to override selectize which is adding a width property directly to
  // this element
  $('#campaign_group_ids-selectized').css('width', '')

  $('#campaign_include_promotion_false').change(function(){
    $('#camp-promo-fields').hide(400)
    $('#follow-up-fields').hide(400)
  });

  $('#campaign_include_promotion_true').change(function(){
    $('#camp-promo-fields').show(400)
    $('#follow-up-fields').show(400)

    // Scroll to fields
    var tag = $('#promotionForm');
    $('html,body').animate({scrollTop: tag.offset().top},'slow');

    $('#campaign_promotion_attributes_coupon_screen_title').focus()
  });
});
