document.addEventListener('turbolinks:load', function() {
  var selectizeInput = $('.selectize-buy').selectize({
    onItemAdd: function(id, $item) {
      this.clear()
      var name = $item[0].innerText
      $('#modalCustomerName').text(name)
      $('#modalCustomerId').text(id)
      $('#grantGiftModal').modal()
    },
    openOnFocus: false,
    closeAfterSelect: true,
    hideSelected: false,
  });

  $('#grantGiftModal').on('shown.bs.modal', function (e) {
    $('#yesGiftButton').focus()
  })

  if (selectizeInput[0]) {
    selectizeInput[0].selectize.focus()
  }

  // Find a better solution to this
  $('#selectize-buy-selectized').css('width', '')

  $('#yesGiftButton').click(function() {
    $('#grantGiftModal').modal('toggle')
    var url = $(this).data().url
    var customerId = $('#modalCustomerId').text()

    $.ajax({
      method: 'POST',
      url: url,
      data: {
        customer_id: customerId,
      },
      success: function(data, textStatus, xhr) {
        selectizeInput[0].selectize.focus()
        if (xhr.status < 300) {
        }
      },
      error: function(xhr, textStatus, err) {
        if (xhr.status > 400) {
          alert('I had an error :(')
        }
      },
    })
  })
});
