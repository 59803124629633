// Using this listener because the "light" layout doesn't load jQuery
document.addEventListener("DOMContentLoaded", function() {
  var notRedeemedImage = document.getElementById('notRedeemedImage')
  var redeemedImage = document.getElementById('redeemedImage')
  var errorImage = document.getElementById('expiredImage')
  if (notRedeemedImage) {
    var redeemUrl = notRedeemedImage.getAttribute('data-url');
    var subText = document.getElementById('subText')

    function fadeIn(el){
      if (!el.classList.contains('fullOpacity')) {
        el.classList.remove('noOpacity');
        el.classList.add('fullOpacity');
      }
    };

    var mc = new Hammer.Manager(notRedeemedImage);
    var requestSent = false
    mc.add( new Hammer.Tap({ event: 'doubletap', interval: 1000, taps: 2, posThreshold: 100})  );

    mc.on('doubletap', function(ev) {
      if (requestSent) {
        return
      }
      requestSent = true
      var xhr = new XMLHttpRequest();

      xhr.onload = function () {
        if (xhr.status === 400) {
          fadeIn(errorImage)
          subText.innerText = xhr.responseText
        } else {
          fadeIn(redeemedImage)
          subText.innerText = 'REDEEMED!'
        }
      };

      xhr.open('POST', redeemUrl);
      xhr.send()
    });
  }
});
