document.addEventListener('turbolinks:load', function() {
  $(".create_group_btn").click(function() {
    var responseId = this.dataset.responseId
    $('.card-form-wrapper').addClass('d-none')
    $(this).siblings('.card-form-wrapper').removeClass('d-none')
    $(this).siblings('.card-form-wrapper').find('input')[2].focus()
  });

  $(".card-form-exit").click(function() {
    $(this).closest('.card-form-wrapper').addClass('d-none')
  });

  var selectizeInput = $('.selectize-search').selectize({
    onItemAdd: function(id, $item) {
      this.clear()
      addCustomer(id)
    },
    openOnFocus: true,
    closeAfterSelect: true,
    hideSelected: false,
  });


  // Have to override selectize which is adding a width property directly to
  // this element
  $('#group_customers-selectized').css('width', '')


  var addCustomer = function(id) {
    var action = selectizeInput[0].form.action
    $.ajax({
      method: 'POST',
      url: action,
      data: {
        customer_id: id,
      },
      success: function(data, textStatus, xhr) {
        if (xhr.status < 300) {
          incrementGroup()
        }
      }
    })
  }

  $('#customerList').on('click', '.removeCustomer', function() {
    var name = $(this).data().customerName
    var id = $(this).data().customerId
    $('#modalCustomerName').text(name)
    $('#modalCustomerId').text(id)
    $('#removeCusotmerModal').modal()
  })

  $('#yesRemoveButton').click(function() {
    var url = $(this).data().url
    var id = $('#modalCustomerId').text()

    $.ajax({
      method: 'POST',
      url: url,
      data: {
        customer_id: id,
      },
      success: function(data, textStatus, xhr) {
        $('#removeCusotmerModal').modal('toggle')
        if (xhr.status < 300) {
          $('#customerRow-' + id).remove()
          decrementGroup()
        }
      }
    })
  })

  var decrementGroup = function() {
    var size = $('#groupSize')
    var newSize = 
    size.text(Number(size.text()) - 1)
  };

  var incrementGroup = function() {
    var size = $('#groupSize')
    size.text(Number(size.text()) + 1)
  };
});
