document.addEventListener('turbolinks:load', function() {
  $('#signup_message_include_promotion_false').change(function(){
    $('#camp-promo-fields').hide(400)
    $('#follow-up-fields').hide(400)
  });

  $('#signup_message_include_promotion_true').change(function(){
    $('#camp-promo-fields').show(400)
    $('#follow-up-fields').show(400)

    // Scroll to fields
    var tag = $('#promotionForm');
    $('html,body').animate({scrollTop: tag.offset().top},'slow');

    $('#signup_message_promotion_attributes_coupon_screen_title').focus()
  });
});

